import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { FaCheckCircle } from 'react-icons/fa';
import PDFObject from 'pdfobject';

function Home() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentNote, setDocumentNote] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(''); // Add state for fileUrl

  const pdfContainerRef = useRef(null); // Use ref instead of document.getElementById()

  const handleLogout = () => {
    setIsAuthenticated(false);
    window.location.href = '/login';
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/authenticate');
        setIsAuthenticated(response.data.authenticated);
        setUserRole(response.data.userRole);
        if (response.data.authenticated) {
          fetchDocuments(); // Fetch documents if authenticated
        }
      } catch (error) {
        console.error('Authentication check error:', error);
      }
    };

    checkAuth();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axios.get('/api/documents');
      setDocuments(response.data.documents);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type.startsWith('image/'))) {
      setSelectedFile(file);
    } else {
      setErrorMessage('Please select a PDF or image file.');
      setSelectedFile(null);
    }
  };

  const handleNoteChange = (event) => {
    setDocumentNote(event.target.value.slice(0, 255));
  };

  const handleUpload = () => {
    if (!selectedFile) {
      setErrorMessage('Please select a file to upload.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    const formData = new FormData();
    formData.append('receipt', selectedFile);
    formData.append('documentNote', documentNote);

    axios.post('/api/upload', formData)
      .then((response) => {
        console.log('Upload success:', response.data);
        setSuccessMessage('File uploaded successfully!');
        setDocumentNote('');
        setSelectedFile(null);
        document.getElementById('fileInput').value = null;
        fetchDocuments(); // Refresh the list of documents
      })
      .catch((error) => {
        console.error('Upload error:', error);
        if (error.response && error.response.status === 401) {
          setErrorMessage('Sorry, you have been logged out due to inactivity. Please log in again.');
          setTimeout(() => {
            window.location.href = '/login';
          }, 3000);
        } else {
          setErrorMessage('File upload failed. Please try again.');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = async (documentId) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      setIsLoading(true);
      try {
        await axios.delete(`/api/document/${documentId}`);
        setSuccessMessage('Document deleted successfully');
        fetchDocuments(); // Refresh the list
      } catch (error) {
        console.error('Delete error:', error);
        setErrorMessage(error.response?.data?.message || 'Failed to delete document');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const openModal = async (document) => {
    setSelectedDocument(document); // Set selected document
    setShowModal(true); // Show the modal

    try {
      const response = await axios.get(`/api/documents/${document.DocumentID}`);
      const { fileUrl, fileType } = response.data;  // Use the signed S3 URL
      setFileUrl(fileUrl);  // Save the fileUrl in state

      if (pdfContainerRef.current) {
        if (fileType === 'pdf') {
          setTimeout(() => {
            PDFObject.embed(fileUrl, pdfContainerRef.current, {
              height: "500px",
            });
          }, 100); 
        } else if (fileType.match(/(jpg|jpeg|png|gif)/i)) {
          pdfContainerRef.current.innerHTML = `<img src="${fileUrl}" alt="Document" class="img-fluid" />`;
        } else {
          pdfContainerRef.current.innerHTML = `
            <div class="alert alert-info" role="alert">
              <h4 class="alert-heading">${fileType.toUpperCase()} File</h4>
              <p>This file cannot be displayed here. Please download it to view:</p>
              <div class="text-center">
                <a href="${fileUrl}" class="btn btn-primary btn-sm" download>
                  <i class="fa fa-download"></i> Download ${fileType.toUpperCase()} File
                </a>
              </div>
            </div>
          `;
        }
      }
    } catch (error) {
      console.error('Error fetching file:', error);
      if (pdfContainerRef.current) {
        pdfContainerRef.current.innerHTML = `
          <div class="alert alert-danger" role="alert">
            <i class="fa fa-exclamation-circle"></i> Failed to load the document. Please try again later.
          </div>
        `;
      }
    }
  };

  const closeModal = () => {
    setSelectedDocument(null);
    setShowModal(false);
  };

  return (
    <div className="bg-dark text-white min-vh-100">
      <Navbar isAuthenticated={isAuthenticated} onLogout={handleLogout} userRole={userRole} />
      <div className="container mt-5">
        <div className="card shadow-sm p-4 mb-4 bg-dark text-white">
          <div className="card-body">
            <h1 className="card-title text-center mb-4">
              <i className="fas fa-file-upload me-2"></i> Receipt Upload
            </h1>
            <div className="d-flex justify-content-center">
              <div className="input-group">
                <input type="file" id="fileInput" className="form-control" onChange={handleFileChange} accept="image/*,.pdf" />
                <button className="btn btn-secondary" type="button">
                  <i className="fas fa-folder-open"></i>
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <textarea
                className="form-control"
                placeholder="Enter a note (optional)"
                value={documentNote}
                onChange={handleNoteChange}
                maxLength={255}
              />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button 
                className="btn btn-primary btn-sm" 
                onClick={handleUpload} 
                disabled={isLoading || !selectedFile}
              >
                {isLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Uploading...
                  </>
                ) : (
                  <>
                    <i className="fas fa-cloud-upload-alt me-2"></i> Upload
                  </>
                )}
              </button>
            </div>
            {successMessage && (
              <div className="alert alert-success mt-3" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 p-3 border border-info rounded">
            <p className="mb-0">
              <i className="fas fa-info-circle me-2 text-info"></i>
              The Square9 column indicates that the document has been handed off to the Square9 software for further processing.
              Documents that have been handed off to Square9 cannot be deleted.
            </p>
        </div> 
        <div className="card shadow-sm p-4 mb-4 bg-dark text-white">
          <div className="card-body">
            <h2 className="card-title mb-4 text-center">
              <i className="fas fa-history me-2"></i> Document History
            </h2>
            <div className="table-responsive">
              <table className="table table-dark table-striped">
                <thead>
                  <tr>
                    <th>Note</th>
                    <th>Uploaded By</th>
                    <th>Uploaded At</th>
                    <th>File Type</th>
                    <th>Square9</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((document) => (
                    <tr key={document.DocumentID}>
                      <td data-label="Note">{document.DocumentNote}</td>
                      <td data-label="Uploaded By">{document.UploadUserID}</td>
                      <td data-label="Uploaded At">{moment(document.UploadDateAndTimeStamp).format('YYYY-MM-DD HH:mm:ss a')}</td>
                      <td data-label="File Type">{document.FileType}</td>
                      <td data-label="Square9">
                        {document.HasBeenProcessed ? (
                          <FaCheckCircle className="text-success" />
                        ) : (
                          ''
                        )}
                      </td>
                      <td data-label="Action">
                        <button className="btn btn-primary btn-sm me-2 mb-1" onClick={() => openModal(document)}>
                          View
                        </button>
                        {!document.HasBeenProcessed && (
                          <button className="btn btn-danger btn-sm" onClick={() => handleDelete(document.DocumentID)}>
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDocument && (
            <>
              {selectedDocument.FileType === 'pdf' && (
                <div id="pdf-container" ref={pdfContainerRef}></div>
              )}
              {selectedDocument.FileType.match(/(jpg|jpeg|png|gif)/i) && (
                <img src={fileUrl} alt="Document" className="img-fluid" />
              )}
              {!selectedDocument.FileType.match(/(pdf|jpg|jpeg|png|gif)/i) && (
                <div className="alert alert-info" role="alert">
                  <h4 className="alert-heading">{selectedDocument.FileType.toUpperCase()} File</h4>
                  <p>This file cannot be displayed here. Please download it to view:</p>
                  <div className="text-center">
                    <a href={fileUrl} className="btn btn-primary btn-sm" download>
                      <i className="fa fa-download"></i> Download {selectedDocument.FileType.toUpperCase()} File
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Home;

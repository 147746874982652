import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from './AppRoutes';

// Development cd into client and run npm start
// Production cd into client and run npm run build

// When you go to localhost:3000, the BrowserRouter sees that the URL path is /. 
// It then checks the children of Routes to find a matching route. It finds the <Route path="/" element={<Home />} />, 
// and so it renders the Home component.
// Similarly, when you go to localhost:3000/login, BrowserRouter 
// checks the path against the routes defined inside Routes. 
// It matches with <Route path="/login" element={<Login />} />, and thus it renders the Login component.


// Development Server vs. Production Server
// Development Server: During development (when you run npm start), 
// your application is served by a development server provided by Create React App, 
// which is configured to handle SPA (Single Page Application) behavior by default. 
// This means it can handle routing on the client-side, letting React Router intercept route 
// changes and display the appropriate components without needing a server-side handler for each route.
// Production Server: When you build your application for production 
// (using npm run build) and serve it using a Node.js server (or any server),
//  you need to configure the server to always serve the index.html file for all paths. 
//  This is necessary because your React application is still a SPA, and navigation is 
//  handled by JavaScript running in the browser. The server doesn't 
//  know about the /login path or any other client-side route; it just 
//  needs to provide the initial HTML, and then React Router 
//  takes over routing in the browser.

// The "proxy": "http://localhost:8080" line in your package.json file is used during development to proxy API requests from the React development server to your Node.js server.
// When you make a request from your React application to a path that is not handled by the React development server (e.g., /upload), it will forward that request to the specified proxy URL (http://localhost:8080)

// When viewing in Android emulator, use
// the IP address of your computer instead of localhost, like http://10.0.2.2
function App() {
    return (
      <Router>
        <AppRoutes />
      </Router>
    );
  }
  
  export default App;
// AddUserModal.js
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function AddUserModal({ show, onHide, onAddUser }) {
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('Regular');
  const [password, setPassword] = useState('');
  const [inactive, setInactive] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddUser({ userId, name, role, password, inactive });
    setUserId('');
    setName('');
    setRole('Regular');
    setPassword('');
    setInactive(false);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formUserId">
            <Form.Label>User ID</Form.Label>
            <Form.Control
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              maxLength={15}
              required
            />
          </Form.Group>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={50}
              required
            />
          </Form.Group>

          <Form.Group controlId="formRole">
            <Form.Label>Role</Form.Label>
            <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)} required>
              <option value="Regular">Regular</option>
              <option value="Admin">Admin</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formInactive">
            <Form.Check
              type="checkbox"
              label="Inactive"
              checked={inactive}
              onChange={(e) => setInactive(e.target.checked)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3">
            Add User
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUserModal;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AddUserModal from './AddUserModal';

function Users() {
  const [users, setUsers] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/authenticate');
        setIsAuthenticated(response.data.authenticated);
        setUserRole(response.data.userRole);
      } catch (error) {
        console.error('Authentication check error:', error);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users');
        setUsers(response.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (isAuthenticated && userRole === 'Admin') {
      fetchUsers();
    }
  }, [isAuthenticated, userRole]);

  const handleLogout = async () => {
    try {
      await axios.get('/api/logout');
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setName(user.Name);
    setRole(user.Role);
    setInactive(user.Inactive);
    setPassword('');
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setName('');
    setRole('');
    setPassword('');
    setShowModal(false);
  };

  const handleAddUser = async (newUser) => {
    try {
      await axios.post('/api/users', {
        userId: newUser.userId,
        name: newUser.name,
        role: newUser.role,
        password: newUser.password,
        inactive: newUser.inactive,
      });
      const response = await axios.get('/api/users');
      setUsers(response.data.users);
      closeAddModal();
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`/api/users/${selectedUser.UserID}`, {
        name,
        role,
        password,
        inactive,
      });

      // Refresh the user list after successful update
      const response = await axios.get('/api/users');
      setUsers(response.data.users);

      closeModal();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <div className="container mt-5">
      <Navbar isAuthenticated={isAuthenticated} onLogout={handleLogout} userRole={userRole} />
      <h2 className="text-white">Manage Users</h2>
      <button className="btn btn-success btn-sm mb-2" onClick={openAddModal}>
        Add New User
      </button>
      <table className="table table-dark table-striped">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Name</th>
            <th>Role</th>
            <th>Inactive</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.UserID}>
              <td>{user.UserID}</td>
              <td>{user.Name}</td>
              <td>{user.Role}</td>
              <td>{user.Inactive ? 'Yes' : 'No'}</td>
              <td>
                <button className="btn btn-primary btn-sm" onClick={() => openModal(user)}>
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit User Modal */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUserId">
              <Form.Label>User ID</Form.Label>
              <Form.Control type="text" value={selectedUser?.UserID} readOnly />
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={50}
                required
              />
            </Form.Group>

            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)} required>
                <option value="Regular">Regular</option>
                <option value="Admin">Admin</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Leave blank to keep the current password"
              />
            </Form.Group>

            <Form.Group controlId="formInactive">
              <Form.Check
                type="checkbox"
                label="Inactive"
                checked={inactive}
                onChange={(e) => setInactive(e.target.checked)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Update User
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <AddUserModal show={showAddModal} onHide={closeAddModal} onAddUser={handleAddUser} />
    </div>
  );
}

export default Users;
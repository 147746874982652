// client/src/hoc/withAuth.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function withAuth(Component) {
  return function AuthComponent(props) {
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      axios.get('/api/authenticate')
        .then(response => {
          if (response.data.authenticated) {
            setAuthenticated(true);
          } else {
            navigate('/login');
          }
        })
        .catch(() => {
          navigate('/login');
        })
        .finally(() => {
          setLoading(false);
        });
    }, [navigate]);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (authenticated) {
      return <Component {...props} />;
    }

    return null;
  };
}

export default withAuth;

// client/src/components/Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Navbar({ isAuthenticated, onLogout, userRole }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.get('/api/logout');
      onLogout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="img/logo.jpg" alt="Logo" height="30" className="d-inline-block align-top me-2" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
          {isAuthenticated && userRole === 'Admin' && (
            <li className="nav-item">
              <Link className="nav-link text-white" to="/users">
                <i className="fas fa-users me-1"></i> Manage Users
              </Link>
            </li>
          )}

            {isAuthenticated ? (
              <li className="nav-item">
                <button className="btn btn-link nav-link text-white" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt me-1"></i> Logout
                </button>
              </li>
            ) : (
              <li className="nav-item">
                <Link className="nav-link text-white" to="/login">
                  <i className="fas fa-sign-in-alt me-1"></i> Login
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
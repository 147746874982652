// client/src/AppRoutes.js
import React from 'react';
// react router allows us to work with browser, andriod, ios, etc
// react router dom is for web
// react router native is for mobile
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Users from './components/Users';
import withAuth from './hoc/withAuth'; // Import the withAuth HOC

const ProtectedHome = withAuth(Home);
const ProtectedUsers = withAuth(Users);
function AppRoutes() {
  const navigate = useNavigate();

  const handleLogin = () => {
    console.log('User logged in!');
    navigate('/');
  };

  return (
    <Routes>
      <Route path="/login" element={<Login onLogin={handleLogin} />} />
      <Route path="/" element={<ProtectedHome />} /> {/* Protect the Home route */}
      <Route path="/users" element={<ProtectedUsers />} />
    </Routes>
  );
}

export default AppRoutes;
